.print-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two cards per row */
    grid-template-rows: repeat(4, 1fr); /* Three rows of cards */
    gap: 5mm; /* Space between cards */
    width: 190mm; /* Usable width of A4 */
    height: 277mm; /* Usable height of A4 */
    margin: 10mm auto; /* A4 margins */
    page-break-inside: avoid; /* Prevent content splitting across pages */
  }
  
  .card {
    width: 90mm; /* Adjusted width for two cards in a row */
    height: 85mm; /* Adjusted height for three rows */
    box-sizing: border-box;
    border: 1px solid #ccc;
    padding: 5mm;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  @media print {
    .print-container {
      page-break-after: always;
    }
  }
  