@media print {
  .container {
    height: 100%;
    min-height: 100vh;
  }

  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
  }
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
  padding: 20px;
}

.content {
  flex: 1 0 auto; /* Ensures the content stretches to take available space */
}

.footer {
  flex-shrink: 0; /* Keeps footer size intact */
  text-align: center;
  margin-top: auto; /* Pushes the footer to the bottom */
  position: relative; /* Default position for regular viewing */
  bottom: 0; /* Relevant only for printing */
}
p{
  margin: 0;
}
