/* YourComponent.css */

.blinking-row {
    animation: blink-animation 1.5s infinite alternate;
  }
  
  @keyframes blink-animation {
    0% {
      background-color: transparent;
    }
    100% {
      background-color:  #58CEDC; 
    }
  }
  